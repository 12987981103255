import React from "react"

import Layout from '../components/layout';
import {Helmet} from "react-helmet";
import { Title, CompanyPlaceInfo } from '../components/Globals';

const ContactPage = () => {

  const handleSubmit = (event) => {
    event.preventDefault();
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
    let formData = new FormData();
    formData.append('from', event.target.email.value);
    formData.append('to', 'sandip@cymetrixsoft.com');
    formData.append('subject', event.target.subject.value);
    formData.append('text', event.target.message.value);
    fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
      method: 'post',
      headers: headers,
      body: formData,
    })
    .then(function (data) {
      if(data.status === 200) {
        //alert('Message sent successfuly');
        window.location = 'https://www.cymetrixsoft.com/Thankyou/';
      }
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });
  }

  return (<Layout>
    
    <div className="contact-background">
    <Helmet>
        <title>Contact Us | Cymetrix Software</title>
        <meta name="description" content="Ready to see growth in action? Contact us for technology solutions tailored to your unique challenges."/>
        <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/contact-us/" />
      </Helmet>
      <h1 className="text-3xl xl:m-64 md:m-16 sm:m-24 tracking-widest text-white text-uppercase text-center font-bold ">
       WE ARE HERE TO UNDERSTAND YOUR PROBLEMS AND PROVIDE BEST PRACTICES FOR SALESFORCE IMPLEMENTATIONS AND HELP YOU GROW YOUR BUSINESS 
      </h1>
      
    </div>
    <main className="w-full flex flex-col items-center">
      
      <div className="flex flex-col md:flex-row sm:-mx-2 md:mx-2">
        <CompanyPlaceInfo
          img={require('../images/contact/longbeach-ca.jpg')}
          alt={' Cymetrix Software office at Long Beach location'}
          imagetitle={'Long Beach Cymetrix Software Office'}
          title={'Long Beach'}
          address={'5704 Cherry Ave # 144, Long Beach, CA, 90805-4404, United States'}
          email={'info@cymetrixsoft.com'}
          phone={'+1-650-334-4141'}
        />
        <CompanyPlaceInfo
          img={require('../images/contact/mumbai.jpg')}
          imagetitle={'Cymterix software office at Mumbai'}
          alt={'Cymetrix Software headquartered in India with office in Mumbai'}
          title={'Mumbai'}
          address={'12, 1st Floor, Samrudhi Venture Park, MIDC, Andheri East, Mumbai 400093, India'}
          email={'info@cymetrixsoft.com'}
          phone={'+91-9137699685, +918655343081'}
        />
      </div>
      <Title title="Get In Touch " />
      <div className="flex justify-center text-center sma:text-xl sm:text-3xl sma:m-6">Fill in the form below to drop us an email</div>
      <form className="justify-center flex" onSubmit={handleSubmit}>
        <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
          <div className="-mx-3 md:flex mb-6 md:space-x-2">
            <div className="md:w-1/2 mb-6 md:mb-0">
              <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                Enter Your Name*
              </label>
              <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" id="name" name="name"
                    type="text" placeholder="" />
            </div>
            <div className="md:w-1/2">
              <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                Enter Your Email*
              </label>
              <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" id="email" name="email"
                    type="email" placeholder="" required/>
            </div>
          </div>
          <div className="-mx-3 md:flex mb-6">
            <div className="md:w-full">
              <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                Enter Your Subject*
              </label>
              <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                    id="subject" type="text" placeholder="" name="subject" required/>
            </div>
          </div>
          <div className="-mx-3 md:flex mb-6">
            <div className="md:w-full">
              <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                Enter Your Message*
              </label>
              <textarea className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                        id="message" type="text" placeholder="" name="message" required>

              </textarea>
            </div>
          </div>
          <div className="-mx-3 md:flex mt-2">
            <div className="sma:w-full">
              <button
                className="sma:w-full bg-header text-white font-bold py-2 px-4 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
    </main>
  </Layout>
)};

export default ContactPage;
